import { withModalWindow } from 'bv-hocs';
import { setFragment } from 'bv-helpers/location';
import withLazyLoad from 'bv-lazy-load';
import { Spinner } from 'bv-components';
import { useFetch } from 'bv-hooks';
import { getQuestionsData, apiStartInteractionWithQuestionnaire } from '../../safer_gambling_portal/features/sg_questionnaire/api';

const Questionnaire = withLazyLoad(<Spinner />)('questionnaire');
const SGQuestionnaireModal = withModalWindow(
  'javascript.sg.questionnaire.modal_title',
  { className: 'questionnaire-modal' },
)(Questionnaire);

const KycSGQuestionnaireModal = () => {
  const [questionsData, loading] = useFetch(getQuestionsData);

  if (loading) return <Spinner />;

  return (
    <SGQuestionnaireModal
      stateNamespace="saferGamblingQuestionnaire"
      translationsNamespace="javascript.sg.questionnaire"
      successModalButtonLabelKey="javascript.sg.questionnaire.cta.result_ok_cta"
      questionsIdentifiersList={questionsData.identifiers}
      questionnaireId={questionsData.id}
      handleSuccessModalClose={() => setFragment('/')}
      handleFailureModalClose={() => setFragment('/pages/contact-us?from=questionnaire')}
      appliedMask="RG_CONCERN"
      onMountCallback={apiStartInteractionWithQuestionnaire}
    />
  );
};

export default KycSGQuestionnaireModal;
